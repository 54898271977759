<template>
  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Update Imports #{{ formData.id }}</div>
        <div v-if="formState == 'Create'">Create Imports</div>
      </template>

      <EditImports v-if="formState == 'Update'" :key="formKey" :data="formData" :gridApi="formGridApi"
                   :modalFormId="formId" :type="type" @close="closeForm"/>

      <CreateImports v-if="formState == 'Create'" :key="formKey" :data="formData" :gridApi="formGridApi"
                     :modalFormId="formId" :type="type" @close="closeForm"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :extrasData="extrasData" :maxBlocksInCache="maxBlocksInCache" :pagination="pagination"
                   :paginationPageSize="paginationPageSize" :rowData="rowData" :rowModelType="rowModelType"
                   :url="url"
                   className="ag-theme-alpine" domLayout='autoHeight' rowSelection="multiple"
                   @gridReady="onGridReady">
        <template #header_buttons>
          <div v-if="type != 'importspostes' && type != 'importspointages'" class="btn btn-primary"
               @click="openImport('agents')">
            <i class="fa fa-plus"></i>
            Importer agents
          </div>
          <!-- <button v-if="!$route.meta.ispostes && $route.meta.domaine == 'sgs'" class="btn btn-primary"
                  @click="openImport('one')">
              <i class="fa fa-plus"></i>
              Importer one
          </button> -->
          <button v-if="type == 'importspostes'" class="btn btn-primary" @click="openImport('postes')">
            <i class="fa fa-plus"></i>
            Importer postes
          </button>
          <button v-if="type == 'importspointages'" class="btn btn-primary" @click="openImport('pointages')">
            <i class="fa fa-plus"></i>
            Importer pointages
          </button>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';
import moment from 'moment'
import AnalysesImports from './AnalysesImports.vue'


export default {
  name: 'ImportsView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateImports: () => import( "./CreateImports.vue"),
    EditImports: () => import( "./EditImports.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    AnalysesImports,
  },
  props: ['type'],
  data() {

    return {
      formId: "imports",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      file: [],
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/imports-Aggrid1',
      table: 'imports',
      requette: 0,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      // extrasData: {},
      maxBlocksInCache: 1,
      importParamsagents: {
        type: "effectifs",
        allChamps: [
          "nom",
          "prenom",
          "matricule",
          "naissance",
          "embauche",
          "cnamgs",
          "cnss",
          "nationalite",
          "fonction",
          "direction",
          "echelon",
          "categorie",
          "contrat",
        ],
      },
      importParamspostes: {
        type: "postes",
        allChamps: [
          "code",
          "libelle",
          "jours",
          "maxjours",
          "maxnuits",
        ],
      },
      importParamspointages: {
        type: "pointages",
        allChamps: [
          "date",
          "badge",
          "pointeuse",
          "idpointeuse",
        ],
      },
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != "undefined") {
          router = window.routeData;
        }
      } catch (e) {
      }
      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    extrasData: function () {
      let retour = {};
      let params = {};
      if (this.type == 'importspostes') {
        params["imports.type"] = {values: ['postes'], filterType: "set"};
        this.tableKey++
      } else if (this.type == 'importspointages') {
        params["imports.type"] = {values: ['pointages'], filterType: "set"};
        this.tableKey++
      } else {
        params["imports.type"] = {values: ['agents-one', 'agents', 'effectifs'], filterType: "set"};
        // params["type"] = {values: ['effectifs'], filterType: "set"};
        this.tableKey++

      }
      retour["baseFilter"] = params;

      return retour;
    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/imports-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {
    this.columnDefs =
    [
                {
                    field: 'imports.id',
                    headerName: '',
                    suppressCellSelection: true,
                    minWidth: 80, maxWidth: 80,
                    pinned: 'left',
                    hide:true

                },
                {
                    field: null,
                    headerName: '',
                    suppressCellSelection: true,
                    minWidth: 80, maxWidth: 80,
                    pinned: 'left',
                    cellRendererSelector: params => {
                        return {
                            component: 'AgGridBtnClicked',
                            params: {
                                clicked: field => {
                                    this.showForm('Update', field, params.api)
                                },
                                render: `<div class="" style="width:100%;height:100%;background:#28a745;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
                            }
                        };
                    },

                },
                {
                    field: "imports.created_at",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'Importer le',
                    valueFormatter: params => {
                        let retour = params.value
                        try {
                            retour = moment(params.value).format('DD/MM/YYYY à HH:mm')
                        } catch (e) {

                        }
                        return retour
                    }
                },


                {
                    field: "typeseffectifs.libelle",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'type',
                    hide: true,
                    suppressColumnsToolPanel: true,
                    join:{
                        table:'typeseffectifs',
                        champ1:'imports.typeseffectif_id',
                        champ2:'typeseffectifs.id',
                        operateur:'='
                    }
                },


                {
                    field: "typespostes.libelle",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'type',
                    hide: true,
                    suppressColumnsToolPanel: true,
                    join:{
                        table:'typespostes',
                        champ1:'imports.typesposte_id',
                        champ2:'typespostes.id',
                        operateur:'='
                    }
                },


                {
                    field: "imports.typespointages",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'type',
                    hide: true,
                    suppressColumnsToolPanel: true,
                },

                {
                    field: "",
                    valueGetter: this.fullNameGetter,
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'type',
                },
                {
                    field: "imports.type",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'type',
                    hide: true,
                    suppressColumnsToolPanel: true,
                },


                {
                    field: "imports.etats",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'etats',
                    wrapText: true,
                    autoHeight: true,
                    cellRendererSelector: params => {
                        return {
                            component: 'AnalysesImports',
                            params: {
                                validerImport: field => {
                                    this.validerImport(field)
                                },
                                annulerImport: field => {
                                    this.annulerImport(field)
                                },
                            }
                        };
                    },
                },

                {
                    field: "imports.creation",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: 'Nbrs creation',
                },

                {
                    field: "imports.modification",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: "Nbrs d'edition",
                },

                {
                    field: "imports.suppression",
                    sortable: true,
                    filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
                    headerName: "Nbrs supprimer",
                },
                {
                    field: "users.nom",
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true,},
                    headerName: 'importer par',
                    join:{
                        table:'users',
                        champ1:'imports.creat_by',
                        champ2:'users.id',
                        operateur:'='
                    }
                },

            ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }


  },
  methods: {
    fullNameGetter(params) {
            if ((params.data.typespostes.libelle)) {

                return params.data.typespostes.libelle;

            } if (params.data.typeseffectifs.libelle) {

                return params.data.typeseffectifs.libelle;
            } else {

                return params.data.imports.typespointages;
            }
        },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    openImport(element) {
      if (element == "agents") {
        // this.importParamsagents["type"] = "effectifs";

        this.showForm("Create", this.importParamsagents, this.gridApi, "lg");
      } else if (element == "pointages") {
        // this.importParamsagents["type"] = "agents-one";
        this.showForm("Create", this.importParamspointages, this.gridApi, "lg");

      } else {

        this.showForm("Create", this.importParamspostes, this.gridApi, "lg");

      }
    },
    download(filename) {
      // console.log( this.file[2])
      this.isLoading = true;
      this.axios
          .get("/api/downloadImports?file=" + filename)
          .then((response) => {
            this.isLoading = false;
            // window.open(response.data, "_blank");
            this.$toast.success("Operation effectuer avec succes");
          })
          .catch((error) => {
            this.errors = error;
            this.isLoading = false;
            this.$toast.error(
                "Erreur survenue lors de l'enregistrement"
            );
          });
    },
    annulerImport(data) {
      console.log('on veut annuler', data)
    },
    validerImport(data) {

    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
  }
}
</script>
